<template>
  <!-- <el-autocomplete
                        style="width: 100%;"
                        v-model="searchForm.region"
                        :fetch-suggestions="
                          (queryString, cb) =>
                            cb(
                              areaDeptOptions.filter(item =>
                                item.value.includes(queryString)
                              )
                            )
                        "
                        placeholder="请输入地区..."
                      /> 
                      <el-autocomplete
                        style="width: 100%;"
                        v-model="searchForm.country"
                        :fetch-suggestions="
                          (queryString, cb) =>
                            cb(
                              areaOptions.filter(item =>
                                item.value.includes(queryString)
                              )
                            )
                        "
                        placeholder="请输入国家..."
                      />-->
  <div>
    <search-form :items="items" @fnSearch="handleFnSearch" />
    <grid
      :columns="columns"
      :code="$route.path"
      :url="url"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 2300, y: gridHeight }"
    >
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
  </div>
</template>
<script>
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';

import memberApi from '@/api/member.js';
// import db from '@/utils/localstorage';
// import IntegralApi from '@/api/IntegralManagement.js';
// import FileSaver from "file-saver";
// import dateFormat from "../../../utils/dateFormat";
// import XLSX from "xlsx";
import tagApi from '@/api/tag.js';
// import Maturity from '@/api/Maturity.js';
// import { arrayBufferDownload } from '@/utils/arrayBufferDownload';

export default {
  name: 'membersList',
  components: {
    SearchForm,
    Grid,
    Operation,
  },
  data() {
    return {
      items: [
        { key: 'region', label: '地区' },
        { key: 'country', label: '国家' },
        { key: 'name', label: '姓名' },
        { key: 'company', label: '公司' },
        { key: 'tagName', label: '标签' },
        { key: 'jobTitle', label: '职位' },
        { key: 'industryLv1', label: '行业' },
        { key: 'isActivate', label: '激活' },
        { key: 'minLoginCount', label: '登陆次数' },
        { key: 'minLastLoginTime', label: '最后登录时间' },
      ],
      columns: [
        { dataIndex: 'name', title: '姓名' },
        { dataIndex: 'company', title: '公司' },
        { dataIndex: 'jobTitle', title: '职位' },
        { dataIndex: 'industryLv1', title: '行业' },
        { dataIndex: 'country', title: '国家', width: 150 },
        { dataIndex: 'memberLevel', title: '会员等级', width: 100 },
        { dataIndex: 'isActivate', title: '激活状态', width: 100 },
        { dataIndex: 'memberScore', title: '会员积分', width: 100 },
        { dataIndex: 'tagName', title: '标签', width: 150 },
        { dataIndex: 'registerTime', title: '注册时间', width: 190 },
        { dataIndex: 'lastLoginTime', title: '上次登录', width: 190 },
        { dataIndex: 'loginCount', title: '登录总次数', width: 100 },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 50,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'downloadA',
          icon: 'CloudDownloadOutlined',
          permission: 'org:update',
          label: '活动数据模板下载',
          fnClick: this.dowmloadActivityTpl,
        },
        {
          type: 'downloadM',
          icon: 'CloudDownloadOutlined',
          permission: 'org:update',
          label: '会员模板下载',
          fnClick: this.dowmloadMemberInfoTpl,
        },
        {
          type: 'downloadM',
          icon: 'ImportOutlined',
          permission: 'org:update',
          label: '活动数据导入',
          fnClick: this.dowmloadMemberInfoTpl,
        },
        {
          type: 'downloadM',
          icon: 'ImportOutlined',
          permission: 'org:update',
          label: '会员数据导入',
          fnClick: this.dowmloadMemberInfoTpl,
        },
      ],
      options: [
        {
          type: 'view',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: 'list:view',
          fnClick: ({ id }) =>
            this.$router.push(`/vipcenter/list/details/${id}`),
        },
      ],
      url: memberApi.pageUrl,
      gridHeight: document.body.clientHeight - 515,
      search: {},
      // 打包服务器 ? 打包服务器 ： 代理到内网服务器 || 代理到dev服务器
      // SERVER: process.env.VUE_APP_REQUEST_SERVER ? process.env.VUE_APP_REQUEST_SERVER + '/api/account' : process.env.VUE_APP_PROXY_SERVER || process.env.VUE_APP_PROXY_DEV_SERVER + '/api/account',
      areaDeptOptions: [],
      isActivateOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '未激活',
        },
        {
          value: '1',
          label: '待激活',
        },
        {
          value: '2',
          label: '已激活',
        },
      ],
      searchForm: {
        isActivate: '',
        region: '',
        memberLevel: '',
        startCreateTime: '',
        endCreateTime: '',
        tagName: '',
        name: '',
        country: '',
        company: '',
        jobTitle: '',
        industryLv1: '',
        filterUser: [],
        minLastLoginTime: '',
        maxLastLoginTime: '',
        minLoginCount: '',
        maxLoginCount: '',
      },
      importParams: {},
      popoverVisible: false,
      activityCampaignId: '',
      activityList: [],
      JobTitleOptions: [],
      activityIdNameList: [],
      tableData: [],
      current: 1,
      size: 10,
      total: 0,
      loading: false,
      multipleSelection: [],
      dataPicVal: '',
      industryLv1Options: [],
      areaOptions: [],

      tagNameOptions: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          value: '选项2',
          label: '双皮奶',
        },
        {
          value: '选项3',
          label: '蚵仔煎',
        },
        {
          value: '选项4',
          label: '龙须面',
        },
        {
          value: '选项5',
          label: '北京烤鸭',
        },
      ],
      IntegralRecordVisible: false, // 积分记录对话框状态
      IntegralRecordParams: {
        current: 1,
        size: 10,
        total: 0,
        contactCode: '',
        loading: true,
      },
      IntegralRecordData: {},
    };
  },
  // computed: {
  //   // 判断是否跳转详情，进入详情页则隐藏当前页
  //   tenant: () => db.get('TENANT', ''),
  //   uploadBaseUrl: () => process.env.VUE_APP_REQUEST_SERVER,
  //   token: () =>
  //     process.env.NODE_ENV === 'development'
  //       ? 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3MDk0MjYyMjM0OTQ3MzY5OTMiLCJhY2NvdW50IjoiemhhbmdibyIsIm5hbWUiOiLlvKDms6IiLCJvcmdpZCI6MTAwLCJzdGF0aW9uaWQiOjY0NTIwMDkzODI4OTYwNTAyNSwiZXhwIjoxNTkxMjUwMDU4fQ.b1TAN6UtODtQF9rJ2tGDXmPrzjEF5BhoYVtPvXa5MnPWdsaKVCgP_s8VDmAELp0D7pB7VLkFy9V9r0H3Ef7cZZU2vjOYyHfkR3LPbAqFgGkZFYjYPa2q8QnN2I6tHLlQ0Tm6IEqpxLqb3eedSANIBiruYgbVECA7jBLWWIrS-L0'
  //       : db.get('TOKEN', ''),
  //   isShow() {
  //     return this.$route.path.indexOf('memberDetails') === -1;
  //   },
  // },
  // watch: {
  //   'IntegralRecordParams.current'(val) {
  //     this.getIntegralRecord({});
  //   },
  // },
  async mounted() {
    // await this.getAreaOptions();
    //this.getList();
    this.getTagName();
    this.getActivityIdNameList();
    // const areaDeptRes = await IntegralApi.getAreaDeptOptions();
    // this.areaDeptOptions = Object.values(areaDeptRes.AREA_DEPT).map(item => ({
    //   value: item,
    // }));
    // const INDUSTRYRes = await IntegralApi.getIndustryLv1Options();
    // const JobTitleRes = await IntegralApi.getJobTitleOptions();
    // this.JobTitleOptions = Object.values(
    //   JobTitleRes['Job Title']
    // ).map(item => ({ value: item }));
    // this.industryLv1Options = Object.values(INDUSTRYRes.INDUSTRY).map(item => ({
    //   value: item,
    // }));
  },
  methods: {
    handleFnSearch(value) {
      this.search = value;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getList();
    },

    async getByCampaignId() {
      // const res = await Maturity.getByCampaignId({
      //   campaignId: this.activityCampaignId,
      // });
      // this.activityList = res.data;
    },

    async getTagName() {
      const res = await memberApi.getTagName();
      this.tagNameOptions = res.map(item => ({ value: item }));
    },

    async getActivityIdNameList() {
      const res = await tagApi.getActivityIdNameList();
      this.activityIdNameList = res;
    },

    importFiles() {
      const params = {
        activityCode: this.importParams.activityCode + '',
      };
      const values = Object.values(params);
      const validationStatus = values.every(item => item);
      if (!validationStatus) return this.$message.warning('尚未选择活动类型！');
      this.$refs.activityUpload.$refs['upload-inner'].handleClick();
    },

    async dowmloadActivityTpl() {
      const res = await memberApi.dowmloadActivityTpl();
      if (!res) return;
      // arrayBufferDownload({
      //   data: res,
      //   name: '活动参与人员行为导入模板.xlsx',
      // });
    },

    async dowmloadMemberInfoTpl() {
      const res = await memberApi.dowmloadMemberInfoTpl();
      if (!res) return;
      // arrayBufferDownload({
      //   data: res,
      //   name: '会员信息模板.xlsx',
      // });
    },

    // 查看会员积分记录
    async getIntegralRecord({ contactCode }) {
      this.IntegralRecordVisible = true;
      this.IntegralRecordParams.loading = true;
      contactCode && (this.IntegralRecordParams.contactCode = contactCode);
      const res = await memberApi.getIntegralRecord(this.IntegralRecordParams);
      this.IntegralRecordParams.loading = false;

      if (!res) return;

      this.IntegralRecordData = res;
    },

    getList() {
      // this.loading = true;
      // // 把中文地区 转为替换为 英文参数
      // const params = Object.assign({}, this.searchForm, {
      //   country: this.areaChEntransformation(this.searchForm.country, 'En'),
      //   tagName: this.searchForm.tagName.join(','),
      // });
      // params.size = this.size;
      // params.current = this.current;
      // memberApi
      //   .getMembersList(params)
      //   .then(response => {
      //     this.tableData = response.records.map(item =>
      //       Object.assign(item, {
      //         country: this.areaChEntransformation(item.country, 'Ch'),
      //       })
      //     );
      //     this.size = parseInt(response.size);
      //     this.total = parseInt(response.total);
      //     this.loading = false;
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.loading = false;
      //   });
    },

    areaChEntransformation(area, targetLanguage) {
      if (targetLanguage === 'Ch') {
        const areaObj = this.areaOptions.find(areaObj => areaObj.en === area);
        return areaObj ? areaObj.value : area;
      }

      if (targetLanguage === 'En') {
        const areaObj = this.areaOptions.find(
          areaObj => areaObj.value === area
        );
        return areaObj ? areaObj.en : area;
      }
    },

    deleteMembers(data) {
      this.loading = true;
      const params = {
        ids: [],
      };
      params.ids.push(data.id);
      memberApi
        .deleteMembers(params)
        .then(() => {
          this.getList();
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
    leaveFormat(row, column, cellValue) {
      return cellValue ? cellValue : '普通会员';
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.getList();
    },
    //处理排序
    handleScoreSort(column, prop, order) {
      console.log(column, prop, order);
    },
    handleSearch(isSearch) {
      if (isSearch) this.current = 1;
      // console.log(this.searchForm)
      this.getList();
    },

    // 导入成功
    uploadSuccess() {
      this.$notify.success({
        title: '成功',
        message: '导入成功',
      });
      this.getList();
    },

    activateStatus(row) {
      switch (row.isActivate) {
        // case '0':
        //   return '未激活';
        case '1':
          return '待激活';
        case '2':
          return '已激活';
        default:
          return '未激活';
      }
    },

    // 获取地区选择列表
    async getAreaOptions() {
      // const res = await IntegralApi.getAreaOptions();
      // const areas = Object.keys(res.data.AREA);
      // this.areaOptions = areas.map(key => ({
      //   en: key,
      //   value: res.data.AREA[key],
      // }));
    },

    handleUploadSuccess() {
      alert(1);
    },
    handleUploadProgress() {
      //this.loading=true
    },
    //处理会员列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //全部导出
    handleAllExport() {
      // let wb = XLSX.utils.table_to_book(document.querySelector('#out-memberlist'));   // 这里就是表格
      // console.log(document.querySelector('#out-memberlist'),'wb');
      // let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
      // try {
      //   FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), 'table.xlsx');  //table是自己导出文件时的命名
      // } catch (e) {
      //   if (typeof console !== 'undefined')
      //     console.log(e, wbout)
      // }
      // return wbout
      /* original data */
      // let filename = `会员列表-${dateFormat(new Date(), 'day')}.xlsx`;
      // console.log(filename,'filename');
      // let data = [[1,2,3],[true, false, null, "sheetjs"],["foo","bar",new Date("2014-02-19T14:30Z"), "0.3"], ["baz", null, "qux"]]
      // let ws_name = "SheetJS";
      //
      // let wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
      //
      // /* add worksheet to workbook */
      // XLSX.utils.book_append_sheet(wb, ws, ws_name);
      //
      // XLSX.writeFile(wb, filename);
    },
    // 导入数据
    handleChange(res) {
      this.loading = false;
      res.data = res.data || {};
      ``;
      // 上传成功
      if (res.data.result && !res.data.wrongSize) {
        // 刷新列表
        this.getList();
        return this.$notify({
          title: '成功',
          message: '导入成功！',
          type: 'success',
        });
      }

      // 部分成功
      if (res.data.result && res.data.wrongSize) {
        // 刷新列表
        this.getList();
        return this.$notify({
          title: '警告',
          message: res.data.error,
          dangerouslyUseHTMLString: true,
          type: 'warning',
        });
      }

      // 否则导入失败
      this.$notify({
        title: '失败',
        message: '导入失败！',
        type: 'error',
      });
    },

    uploadErr() {
      this.loading = false;
      this.$notify({
        title: '失败',
        message: '很抱歉，导入请求出错了！',
        type: 'error',
      });
    },

    showMessage() {
      // console.log(12)
      this.$alert('模板橙色为必填项，否则将无法正常导入数据！！！', '注意：', {
        type: 'info',
        // center: true,
        customClass: 'msgBox',
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${action}`,
          });
        },
      });
    },

    // // 下载excel模板
    // async downloadTExcel() {
    //   this.loading = true;
    //   const res = await memberApi.downloadTExcel().catch(err => console.log(err));
    //   this.loading = false;
    //   console.log(res)
    // }
  },
};
</script>
